<template>
  <section class="pt-0 pb-0" style="min-height: 70vh; width: 100vw">
    <div class="image-cover ed-detail-head invers" style="background: #fff">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center" style="padding: 0">
            <b-button class="btn btn-remove" :to="{ name: 'subjects' }"
              >Apply
              <!-- <b-icon-plus-circle></b-icon-plus-circle> -->
              <b-icon-cursor-fill></b-icon-cursor-fill>
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <!-- <section class="pt-0 pb-0">
    <div class="col-12 text-right" style="background: green">
      <b-button class="btn btn-light" :to="{ name: 'subjects' }"
        >Add subjects
        <b-icon-plus-circle></b-icon-plus-circle>
      </b-button>
    </div> -->
    <div class="dashboard-container-body" id="tutoring-main-body">
      <div v-if="st_tutorSubjects && st_tutorSubjects.length > 0">
        <div
          class="dashboard-single-course"
          v-for="p_subject in st_tutorSubjects"
          :key="p_subject.id"
        >
          <div class="dashboard-single-course-caption">
            <div class="dashboard-single-course-head">
              <div class="dashboard-single-course-head-flex">
                <!-- <span class="dashboard_instructor">{{ p_subject.status }}</span> -->
                <h5 class="dashboard-course-title">
                  {{ p_subject.en }}
                </h5>
                <span class="grade-main-display" v-if="p_subject.grade"
                  >Grade: {{ p_subject.grade }}</span
                >
                <span
                  class="curriculum-main-display"
                  v-if="p_subject.curriculums"
                  >Curriculums:
                  <span style="text-transform: uppercase">{{
                    p_subject.curriculums
                  }}</span></span
                >
              </div>
              <div class="dc-head-right">
                <h5 class="dc_price_rate theme-cl">&nbsp;</h5>
                <h4>
                  <b-badge pill :variant="m_statusColor(p_subject.status)">{{
                    m_statusLabel(p_subject.status)
                  }}</b-badge>
                </h4>
              </div>
            </div>
            <div class="dashboard-single-course-des"></div>
          </div>
        </div>
      </div>
      <div v-else class="empty-tutoring-subjects">
        No tutoring subjects yet. Apply now!
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "cmp-tutoring",
  computed: {
    ...mapState({
      st_tutorSubjects: (state) => state.md_fire.st_tutorSubjects,
    }),
  },
  mounted() {
    this.$store.commit("md_fire/mt_setDashboardSection", "tutoringsubjects");
  },
  methods: {
    m_statusLabel(p_status) {
      switch (p_status) {
        case "A":
          return "Active";
        case "R":
          return "Under Review";
        case "D":
          return "Denied";
        default:
          return "";
      }
    },
    m_statusColor(p_status) {
      switch (p_status) {
        case "A":
          return "primary";
        case "R":
          return "warning";
        case "D":
          return "danger";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.empty-tutoring-subjects {
  text-align: center;
  width: 100%;
  padding-top: 20vh;
  padding-bottom: 20vh;
  margin: auto;
  min-height: 50vh;
  border: 1px solid #dadfea;

  border-radius: 0.5rem;
}

.ed-detail-head {
  padding-left: 0;
  padding-right: 0;
}

.grade-main-display {
  display: block;
  margin-top: 10px;
}
.curriculum-main-display {
  display: block;
}

@media (max-width: 700px) {
  .ed-detail-head {
    text-align: center;
  }

  .dashboard-single-course {
    padding: 0;
    padding-top: 10px;
    padding-right: 10px;

    border-radius: 0;
  }

  .empty-tutoring-subjects {
    border-radius: 0;
  }
}
</style>

